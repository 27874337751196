@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

h2 {
    font-family: 'Quicksand', sans-serif; 
    font-size: 20px; /* Adjust the font size */
    color: #333; /* Set the text color */
}

.btn-newquill {
    font-weight: 900;  /* Optional: Set the font weight to bold */
    font-size: 1.1rem; 
    padding: 10px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);  
    border-radius: 20px; 
}

button {
    font-family: 'Quicksand', sans-serif;   /* Apply the custom font */
  /* Optional: Adjust the font size */
    color: #fff;       /* Optional: Set the text color */
    background-color: #007bff;  /* Optional: Set a background color */
    border: none;      /* Optional: Remove default border */
    padding: 10px; /* Optional: Adjust padding */
    border-radius: 20px; /* Optional: Slightly round the corners */
    cursor: pointer;    /* Optional: Change cursor on hover */  
    box-shadow: '0px 4px 8px rgba(0, 0, 0, 0.6)';
}

.custom-header {
    font-size: 1.5rem;  /* Increase font size */
    font-weight: 300;  /* Reduce font weight */
    color: #fff;  /* Text color */

  }
  

