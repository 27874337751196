@media print {
    .print-page {
        margin: 1in .2in; /* 1.5 inches top and bottom, 1 inch left and right */
        gap: '20px 80px'
      }
  }

  @media print and (max-device-width: 480px) {
    .print-page {
        margin: 1.4in 0.6in; /* Adjusted margin for mobile devices */
        gap: '18px 80px'
    }
  }

  .qr-container {
    text-align: center;
    width: 100%; /* Ensure the container width matches the QR code */
  }
  
  .qr-friendly-name {
    margin-top: 0px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; /* Match the QR code's width */
    color: black;
  }
  
  