.install-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .install-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .install-button svg {
    color: #eff0f0; /* Icon color */
  }
  
  .install-button:hover svg {
    color: #0056b3; /* Icon color on hover */
  }
  