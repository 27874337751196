.text-white{
    font-family: 'Quicksand', sans-serif;   /* Apply the custom font */

}

.qr-button {
    padding: 0; /* Remove all padding from the button itself */
    border: none; /* Remove border */
    border-radius: 8px; /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Automatically adjust height based on content */
}


.qr-button > div {
    display: flex; /* Use flexbox for centering content */
    align-items: center;
    justify-content: center;
    padding: 5px; /* Add even padding inside the container */
    height: 100%; /* Ensure it fills the button vertically */
    line-height: 0; /* Ensure no extra space due to line height */
    /* box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.5); Apply shadow to the container */
}

.qr-button img {
    display: block;
    margin: 0 auto;
    /* If the shadow on the container isn't sufficient, you can add another shadow here */
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); */
}


.qr-button > img {
    display: block;
    margin: 0 auto;
}


/* Default button styles */
.responsive-button {
    padding: 0.375rem 0.75rem; /* Default padding */
    font-size: .96rem;           /* Default font size */
    min-width: 100px;
  }
  
  .btn-secondary.dark {
    background-color: #6c757d; /* Default Bootstrap secondary color */
    color: white;
    border-color: #6c757d;
  }

  .btn-secondary.dark:hover {
    background-color: #5a6268;
    border-color: #545b62;
    color:white
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #1f4364f6;
    padding: 15px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .small-text {
    font-size: 0.9rem; /* Adjust this value to make the text smaller */
  }
  
  .smaller-text {
    font-size: 0.8rem; /* Adjust this value to make the parenthesis text even smaller */
  }

 span.view-count-badge.badge.badge-primary {
    color: rgb(255, 255, 255);
    background-color: #5f6cde;
 }
  
  
  /* Hide the fixed footer on larger screens */
  @media (min-width: 768px) {
    .fixed-footer {
      display: none;
    }
  }