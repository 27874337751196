/* Fullscreen overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #525f7f;
    /* background: inherit; Inherit background color from the body or parent element */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Card-like popup */
  .modal-card {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Title */
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  /* Scrollable text area */
  .modal-body {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .policy-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.4;
  }
  
  /* Loading spinner */
  .modal-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
  }
  
  /* Footer actions */
  .modal-footer {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }
  
  .modal-footer button {
    flex: 1;
  }
  