@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .nav-link-inner--text {
    font-family: 'Quicksand', sans-serif; 
  }

  .nav-link-small {
    font-size: 0.6rem; /* Adjust font size to make it smaller */
    text-decoration: underline; /* Underline the text */
    color: inherit; /* Keep the existing color */
    transition: color 0.3s ease; /* Add smooth hover effect */
  }
  
  .nav-link-small:hover {
    color: #d5102b; /* Change color on hover (optional) */
  }

  @media (max-width: 400px) {
    .nav-link-icon i {
      max-height: "10px"
    }

    .navbar-brand img {
        max-height: 110px; /* Adjust as needed */
      }

    .navbar-nav .nav-item{
        padding-right: .4rem;
    }
  
    .nav-link-inner--text {
      font-size: 1rem;
    }
  }
  