/* Default (Desktop) */
.card-profile-image img {
    max-width: 180px; 
    border-radius: 0.375rem;
    transform: translate(-50%, -40%) !important;
    position: absolute;
    left: 50%;
    transition: all 0.15s ease;
  }
  
  /* Mobile (max-width 768px) */
  @media only screen and (max-width: 768px) {
    .card-profile-image img {
      transform: translate(-50%, -90%) !important;
    }
  }
  
  .card-profile-stats {
    padding: 0rem 0 !important;
  }

  .card-profile-stats > div {
    text-align: center;
    margin-right: 1rem;
    padding: 0.5rem !important;
  }