@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

.whatis-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between items */
  width: 100%;
}

.whatis-item {
  overflow: hidden; /* Ensures content stays within the div */
  width: 100%; /* Stretches to fill container width */
  display: flex;
  flex-direction: column; /* Stack content vertically */
}

.whatis-image {
  width: 100%; /* Full width image */
  height: 33.33%; /* Make the image take up the top third of the div */
  object-fit: cover; /* Cover the space while maintaining aspect ratio */
}

/* Media query for larger screens (desktop) */
@media (min-width: 768px) {
    .whatis-image {
      width: 25%; /* Adjust the width for desktop */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center the image */
      border-radius: 10px;
    }
    .whatis-item .whatis-description {
      text-align: center;
      font-size: 1.7em; /* Regular text size for descriptions */
      padding-left: 20%;
      padding-right: 20%;
    }

}

.whatis-content {
  padding: 20px; /* Padding inside each use case div */
  flex-grow: 1; /* Allow content to grow and fill the space */
}

h1.whatis-title {
  font-size: 2em; /* Larger text for headers */
  color: #fdfdfd; /* White text for headers */
}

.whatis-title {
  font-family: 'Quicksand', sans-serif; /* Apply the custom font */
  font-size: 2.3em; /* Larger text for headers */
  margin-bottom: 10px; /* Space below the title */
  margin-top: 120px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre-line; /* This allows the text to honor new lines */
  font-weight: 500;
}

.whatis-description {
  font-family: 'Quicksand', sans-serif; /* Apply the custom font */
  font-size: 1.2em; /* Regular text size for descriptions */
  line-height: 1.6; /* Improved readability */
  margin-bottom: 100px;
  white-space: pre-line; /* This allows the text to honor new lines */
  color: #fdfdfd; /* White text for headers */

}
