.note {
    position: relative;
    font-family: 'Cabin', sans-serif;
    margin: 30px auto;
    background: linear-gradient(135deg, #f2f2f2 40%, #c4c4c4 70%, #686767); /* Emphasize lighter color in top-left */
    background-size: 300% 300%; /* Enhanced background size for more noticeable movement */
    animation: gradient-move 4s linear infinite; /* Slowed down the animation */
    border: 1px solid #ddd;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow for emphasis */
    overflow: hidden;
    font-size: 2.3vw;
    max-width: 800px;
    min-height: 500px;
    min-width: 800px;
    width: 100%;

}

.note.stop-gradient {
    animation: none; /* Stop the gradient animation */
    background: #f2f2ec; /* Set the final background color */
}

.note-body {
    min-height: 500px;
    min-width: 550px;
    opacity: 0; /* Start with the text invisible */
    transition: opacity 1s ease; /* Smooth fade-in effect */
    white-space: pre-wrap;
}

.note-body.reveal {
    opacity: 1; /* Fully visible when data is ready */
}

/* Gradient background animation */
@keyframes gradient-move {
    0% {
        background-position: bottom left;
    }
    100% {
        background-position: top right;
    }
}

@media (min-width: 600px) {
    .note {
        font-size: 28px;
    }
}

@media (max-width: 600px) {
    .note {
        width: 95%;
        min-height: 60vh;
        margin: 5vh auto;
        min-width: 370px;
        font-size: 7vw;
    }

    .note-body {
        min-width: 350px;
    }

    .note-body.reveal {
        opacity: 1;
    }
}
