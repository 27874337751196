@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');

/* Adjust the position of the previous and next arrows */
.carousel-control-prev,
.carousel-control-next {
  bottom: 20px; /* Move the arrows down by 20px */
  top: auto;    /* Remove the default top alignment */
}

/* Optional: Adjust the size of the arrows if needed */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px;
  height: 20px;
}


.instructions-card {
    margin: auto; /* Center the card horizontally */
    display: flex;
    justify-content: center;
    min-height: 450px;
    align-items: center;
    background: linear-gradient(to right, #eff0f2, #ffffff); /* Gradient from light navy to white */  }

.instructions-body {
    padding: .5rem;
}

.card-text{
    font-weight: 500;
    font-size: 1.1rem;
}

.card-title{
    font-family: 'Quicksand', sans-serif; 
    font-weight: 500;
    font-size: 1.8rem;
}
  