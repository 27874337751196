@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

/* Your other CSS styles here */


.header-title {
    font-size: 3rem;
    font-weight: 400; /* Light weight to avoid boldness */
    margin-bottom: 10px;
    color: #ffffff; /* Slightly darker off-white for the header */
    font-family: 'Quicksand', sans-serif; 
    /* font-family: 'Nunito', sans-serif;    */
    padding-bottom: 0px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9); /* Add a subtle shadow */
  }

  .header-sub-title {
    font-size: 1.3rem;
    font-weight: 200; /* Light weight to avoid boldness */
    color: #ffffff; /* Slightly darker off-white for the header */
    font-family: 'Quicksand', sans-serif; 
    /* font-family: 'Nunito', sans-serif;    */
    padding-bottom: 50px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9); /* Add a subtle shadow */
  }
  
  .header-paragraph {
    font-size: 1.0rem;
    color: #ffffff; /* Lighter off-white for the paragraphs */
    padding-bottom: 0px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9); /* Add a subtle shadow */

  }

  .header-paragraph-center {
    text-align: center;
    font-family: 'Quicksand', sans-serif; 
    color: #ffffff; /* Lighter off-white for the paragraphs */


  }
  
  .quill-image-white {
    filter: invert(1);
    width: 100%;  /* Full width on mobile */
    max-width: 320px;  /* Constrain width on larger screens */
    height: auto;  /* Maintain aspect ratio */
    vertical-align: top;  /* Align the image with the text */
    padding: 0;  /* Ensure no padding */

  }
  
  @media (max-width: 500px) {
    .quill-image-white {
      width: 260px;  /* Set the width to 320px on desktop */
    }
  }