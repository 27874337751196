.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-width: 100%;
    max-height: 3.4rem !important;
}

.navbar {
    padding: .6rem  !important;
  }

.navbar-nav .nav-item{
    padding-right: .1rem;
}

  .navbar-horizontal .navbar-nav .nav-link {
    font-size: 1.1rem !important;
  }